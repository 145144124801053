import { MouseEventHandler, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import TechnicianCreate from './TechnicianCreate'
import TechnicianEdit from './TechnicianEdit'
import CircularProgress from '@mui/material/CircularProgress'
import {
  createTech,
  editTech,
  editTechWeekDays,
  setTechToLocation,
} from '../../api'
import { useFetchNoEffect } from '../../../../../global/hooks/useFetchNoUseEffectHook'
import { fetchData } from '../../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { IAlertStatusProps } from '../../../../../global/components/toast-alert/ToastNotification'
import { ITechnician } from '../../../../../../../app/entities/Technician'
import { IWeekCalendar } from '../../../../../../../app/entities/WeekCalendar'
import {
  initialDaySettingFormValues,
  initialTechFormInfoValues,
  initialWeekCalendarFormValues,
} from '../utils'

interface ITechnicianModalProps {
  isOpen: boolean
  handleClosed: MouseEventHandler
  techId?: string
}

export interface ITechnicianFormInfoProps {
  name: string
  email: string
  bio: string
  techAvatar: string | null
  employeeId: string
  samsaraId: number | null
  inactive: boolean
  workDayFrom: string
  workDayTo: string
  lunchFrom: string
  lunchTo: string
  lunchDuration: number
  technicianId: string
  locationId: string | null
  locationRoutingAlgorithm: string | null
  defaultWorkDayFrom: string | null
  defaultWorkDayTo: string | null
  defaultLunchFrom: string | null
  defaultLunchTo: string | null
  defaultLunchDuration: number | null
  useDefaultLunch: boolean
  useDefaultWorkDay: boolean
}
export interface ITechnicianWorkingDaysProps {
  weekCalendars: {
    friday: boolean
    monday: boolean
    objectId: string
    saturday: boolean
    sunday: boolean
    thursday: boolean
    tuesday: boolean
    wednesday: boolean
    weekName: string
  }[]
}
export interface ITechnicianUniqueDaysProps {
  daySettings: {
    lunchFrom: string
    lunchTo: string
    lunchDuration: string
    workDay: boolean
    reason: string
    startTime: string
    endTime: string
    dayDate: string
    objectId: string
  }[]
}

export function TechnicianModal(props: ITechnicianModalProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const [isLoading, setIsLoading] = useState(false)
  const [alertStatus, setAlertStatus] = useState<IAlertStatusProps>({
    isOpen: false,
    message: '',
    severity: 'error',
  })
  const [technicianFormInfo, setTechnicianFormInfo] =
    useState<ITechnicianFormInfoProps>(initialTechFormInfoValues)
  const [weekCalendarsFormInfo, setWeekCalendarsFormInfo] = useState(
    initialWeekCalendarFormValues,
  )
  const [daySettingsFormInfo, setDaySettingsFormInfo] = useState(
    initialDaySettingFormValues,
  )

  const {
    name,
    email,
    bio,
    employeeId,
    samsaraId,
    inactive,
    workDayFrom,
    workDayTo,
    lunchDuration,
    lunchFrom,
    lunchTo,
    useDefaultLunch,
    useDefaultWorkDay,
    locationId,
    defaultWorkDayFrom,
    defaultWorkDayTo,
  } = technicianFormInfo

  useEffect(() => {
    if (locationId && useDefaultWorkDay) {
      // Ensure form updates with default values
      setTechnicianFormInfo((prevInfo) => ({
        ...prevInfo,
        workDayFrom: defaultWorkDayFrom || prevInfo.workDayFrom,
        workDayTo: defaultWorkDayTo || prevInfo.workDayTo,
      }))
    }
  }, [defaultWorkDayFrom, defaultWorkDayTo, locationId, useDefaultWorkDay])

  function handleClose(e: any) {
    setTechnicianFormInfo(initialTechFormInfoValues)
    setWeekCalendarsFormInfo(initialWeekCalendarFormValues)
    setDaySettingsFormInfo(initialDaySettingFormValues)
    setIsLoading(false)
    props.handleClosed(e)
  }

  const { weekCalendars } = weekCalendarsFormInfo

  const body = {
    name,
    email,
    bio,
    employeeId,
    samsaraId: Number(samsaraId),
    inactive,
    workDayFrom: useDefaultWorkDay ? null : workDayFrom,
    workDayTo: useDefaultWorkDay ? null : workDayTo,
    lunchFrom: useDefaultLunch ? null : lunchFrom,
    lunchTo: useDefaultLunch ? null : lunchTo,
    lunchDuration: useDefaultLunch ? null : lunchDuration,
    weekCalendars,
    objectId: props.techId,
  }
  const { handleFetchData: editTechnician } = useFetchNoEffect(editTech(body))

  async function handleSaveTech(e: any) {
    try {
      setIsLoading(true)
      await editTechnician()
      await fetchData<IWeekCalendar>(
        editTechWeekDays(weekCalendars[0], props.techId),
      )
      await fetchData<IWeekCalendar>(
        editTechWeekDays(weekCalendars[1], props.techId),
      )
      handleClose(e)
    } catch (error) {
      console.error('Error editing technician:', error)
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Error editing technician',
      })
    } finally {
      setIsLoading(false)
    }
  }
  async function handleCreateTech(e: any) {
    try {
      setIsLoading(true)
      const createTechResponse = await fetchData<ITechnician>(createTech(body))
      await fetchData<ITechnician>(
        setTechToLocation(
          technicianFormInfo.locationId,
          createTechResponse.objectId,
        ),
      )
      const evenWeek = createTechResponse.weekCalendars.filter(
        (week: any) => week.weekName === 'even',
      )
      const oddWeek = createTechResponse.weekCalendars.filter(
        (week: any) => week.weekName === 'odd',
      )
      const evenWeekCalendarsWithId = weekCalendars[0]
      const oddWeekCalendarsWithId = weekCalendars[1]
      oddWeekCalendarsWithId.objectId = oddWeek[0].objectId
      evenWeekCalendarsWithId.objectId = evenWeek[0].objectId
      await fetchData<IWeekCalendar>(
        editTechWeekDays(evenWeekCalendarsWithId, createTechResponse.objectId),
      )
      await fetchData<IWeekCalendar>(
        editTechWeekDays(oddWeekCalendarsWithId, createTechResponse.objectId),
      )
      handleClose(e)
    } catch (error) {
      console.error('Error creating technician:', error)
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Error creating technician',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const technicianFormInfoIsInvalid =
    !technicianFormInfo.name ||
    !technicianFormInfo.email ||
    !technicianFormInfo.locationId ||
    !technicianFormInfo.employeeId ||
    !technicianFormInfo.samsaraId ||
    technicianFormInfo.workDayTo <= technicianFormInfo.workDayFrom

  return (
    <div>
      <Modal
        open={props.isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.techModal}>
          <>
            <div className={styles.locationModalHeader}>
              <div className={`${styles.locationModalPageTitle} font--bold`}>
                {props.techId ? 'Edit Technician' : 'Create New Technician'}
              </div>
              <div className={styles.locationModalHeaderButtons}>
                <SecondaryButton
                  buttonName='Cancel'
                  onClick={handleClose}
                />
                {props.techId ? (
                  <PrimaryButton
                    disabled={isLoading || technicianFormInfoIsInvalid}
                    buttonName='Save Changes'
                    onClick={handleSaveTech}
                  />
                ) : (
                  <PrimaryButton
                    buttonName='Create Tech'
                    disabled={isLoading || technicianFormInfoIsInvalid}
                    onClick={handleCreateTech}
                  />
                )}
              </div>
            </div>
            {isLoading && (
              <div className={styles.progressContainer}>
                <CircularProgress />
              </div>
            )}
            {!isLoading && props.techId && (
              <TechnicianEdit
                formInfo={{ technicianFormInfo, setTechnicianFormInfo }}
                weekCalendars={{
                  weekCalendarsFormInfo,
                  setWeekCalendarsFormInfo,
                }}
                daySettings={{ daySettingsFormInfo, setDaySettingsFormInfo }}
                techId={props.techId}
              />
            )}
            {!isLoading && !props.techId && (
              <TechnicianCreate
                formInfo={{ technicianFormInfo, setTechnicianFormInfo }}
                weekCalendars={{
                  setWeekCalendarsFormInfo,
                  weekCalendarsFormInfo,
                }}
                daySettings={{ setDaySettingsFormInfo, daySettingsFormInfo }}
              />
            )}
          </>
        </Box>
      </Modal>
    </div>
  )
}
