import { SelectTireSize } from './select-size/SelectTireSize'
import SizeQuantity from './select-quantity/SelectQuantity'
import styles from './styles.module.css'
import { IScheduleModalComponentProps } from '../container-modal/ScheduleModal'
import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import {
  createTireCombination,
  getPsiTorqueSuggestions,
  setTireCombination,
  updateTireCombination,
  updateVehicleMileage,
  handleGenericItems,
} from './api'
import { MODES } from '../../../../../global/constants/scheduler'
import CircularProgress from '@mui/material/CircularProgress'
import { fetchData } from '../../../../../global/utils/fetch'
import { ITiresCombination } from '../../../../../../../app/entities/TiresCombination'
import { IOrderVehicle } from '../../../../../../../app/entities/OrderVehicle'
import VehicleDetails from './details/VehicleDetails'
import TireDisposalQuantity from './disposal/TireDisposalQuantity'
import {
  ISchedulerStateProps,
  ITiresProps,
} from '../container-modal/initialData'
import { checkTireFormFields, handleCreateAndDeleteOrderService } from './utils'
import useDetectChangeOrderService from './useDetectChangeOrderService'
import { useParams } from 'react-router-dom'
import { toggleTireDisposal } from '../order-summary/api'
import PsiTorqueModal from './psi-torque-modals/PsiTorqueModal'
import psiTorqueMatches from '../../../../../global/constants/psiTorqueMatches.json'
import GenericTires from './generic-tires/GenericTires'
export interface ITireSizeError {
  width: boolean
  diameter: boolean
  aspectRatio: boolean
}

export interface FullSuggestion {
  frontPsi: number
  rearPsi: number
  torque: number
}

export interface PsiTorqueSuggestions {
  fullSuggestions: FullSuggestion[]
  torqueSuggestions: number[]
}

export const SUGGESTIONS_ALERT_MESSAGES = {
  default: 'Loading suggestions...',
  enterInformation: 'Please enter tire size',
  noMatch: 'No matches found',
  uniqueMatch: 'Unique match found',
  someMatches: 'Matches found',
  uniqueTorque: 'Unique torque found',
  torqueOnly: 'Only torque found',
}

export const TireSizeQuantity = forwardRef(
  (props: IScheduleModalComponentProps, ref) => {
    const { id } = useParams()
    const {
      schedulerState,
      setSchedulerState,
      mode,
      toggleToastError,
      setDisabled,
      order,
    } = props
    const { customerInformation } = schedulerState
    const {
      newOrderService,
      serviceHasChanged,
      disposalQtyHasChanged,
      tireQuantityHasChanged,
    } = useDetectChangeOrderService({ schedulerState, setSchedulerState })

    const [isLoading, setIsLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState(schedulerState.tires)
    const [isPsiTorqueModalOpen, setIsPsiTorqueModalOpen] = useState(false)
    const [tireSizeError, setTireSizeError] = useState<ITireSizeError>({
      width: false,
      diameter: false,
      aspectRatio: false,
    })
    const [psiTorqueSuggestions, setPsiTorqueSuggestions] =
      useState<PsiTorqueSuggestions>({
        fullSuggestions: [],
        torqueSuggestions: [],
      })
    const [alertStatus, setAlertStatus] = useState<string>(
      SUGGESTIONS_ALERT_MESSAGES.default,
    )
    const [hasWarnedOfPriceChangeWhenDiscountsAlreadyAttached, setHasWarned] =
      useState(false)

    useEffect(() => {
      const orderHasDiscounts = order?.discounts?.length

      const priceAffectingChangeOccurred =
        serviceHasChanged || tireQuantityHasChanged || disposalQtyHasChanged

      if (
        priceAffectingChangeOccurred &&
        orderHasDiscounts &&
        !hasWarnedOfPriceChangeWhenDiscountsAlreadyAttached
      ) {
        alert(
          'This change may affect the amount discounted from this order. Consider removing and re-adding discounts, if necessary.',
        )
        setHasWarned(true)
      }
    }, [
      serviceHasChanged,
      tireQuantityHasChanged,
      disposalQtyHasChanged,
      order,
    ])

    const { alertProps } = (psiTorqueMatches as any)[alertStatus] ?? {}

    function updateSchedulerStateTires(tires: ITiresProps) {
      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        tires: {
          ...prevState.tires,
          frontTire: {
            ...prevState.tires.frontTire,
            ...tires.frontTire,
            psi: prevState.tires.frontTire.psi,
            torque: prevState.tires.frontTire.torque,
          },
          rearTire: {
            ...prevState.tires.rearTire,
            ...tires.rearTire,
            psi: prevState.tires.rearTire.psi,
            torque: prevState.tires.rearTire.torque,
          },
        },
      }))
    }

    function setPsiTorqueModalOpen() {
      setIsPsiTorqueModalOpen(true)
    }

    async function handleNextClickLogic(goToNextPage: Function) {
      setIsLoading(true)
      updateSchedulerStateTires(selectedItem)

      if (mode === MODES.CUSTOMER || mode === MODES.FINISH) {
        try {
          await handleSaveClickLogic()
        } catch (error) {
          toggleToastError && toggleToastError(true)
          throw error
        } finally {
          setDisabled(false)
          setIsLoading(false)
        }
      }
      goToNextPage(true)
    }

    async function handleSaveClickLogic() {
      setDisabled(true)
      setIsLoading(true)

      const { tires, orderId, orderVehicleId, tireDisposal, disposalQty } =
        schedulerState
      const { mileage } = customerInformation

      const genericItems = schedulerState.genericItems

      try {
        await fetchData(handleGenericItems(id as string, genericItems || []))
        serviceHasChanged &&
          (await handleCreateAndDeleteOrderService(
            newOrderService,
            id as string,
            schedulerState,
            setSchedulerState,
          ))
        tires.objectId
          ? await fetchData<ITiresCombination>(updateTireCombination(tires))
          : await handleCreateTireCombo(tires, orderId, orderVehicleId)
        disposalQtyHasChanged &&
          (await fetchData(
            toggleTireDisposal(
              orderId,
              orderVehicleId,
              tireDisposal,
              disposalQty,
            ),
          ))
        mileage &&
          (await fetchData<void>(
            updateVehicleMileage(schedulerState.orderVehicleId, mileage),
          ))
      } catch (error) {
        throw error
      } finally {
        setDisabled(false)
        setIsLoading(false)
      }
    }

    async function handleCreateTireCombo(
      tires: any,
      orderId: string,
      orderVehicleId: string,
    ) {
      const tireCombo = await fetchData<ITiresCombination>(
        createTireCombination(tires),
      )
      await fetchData<IOrderVehicle>(
        setTireCombination(tireCombo, orderId, orderVehicleId),
      )
    }

    function handlePsiTorqueModalConfirm(suggestion: FullSuggestion | number) {
      setIsPsiTorqueModalOpen(false)
      const isSuggestionJustTorque = typeof suggestion === 'number'
      const hasOneFullSuggestion = true
      if (isSuggestionJustTorque) {
        const newValue = suggestion

        setSchedulerState((prevState: ISchedulerStateProps) => ({
          ...prevState,
          tires: {
            ...prevState.tires,
            frontTire: {
              ...prevState.tires.frontTire,
              psi: null,
              torque: newValue,
            },
            rearTire: {
              ...prevState.tires.rearTire,
              psi: null,
              torque: newValue,
            },
          },
        }))
      } else if (hasOneFullSuggestion) {
        const newValues = suggestion

        setSchedulerState((prevState: ISchedulerStateProps) => ({
          ...prevState,
          tires: {
            ...prevState.tires,
            frontTire: {
              ...prevState.tires.frontTire,
              psi: newValues.frontPsi,
              torque: newValues.torque,
            },
            rearTire: {
              ...prevState.tires.rearTire,
              psi: newValues.rearPsi,
              torque: newValues.torque,
            },
          },
        }))
      }
    }

    useImperativeHandle(
      ref,
      () => ({
        async handleNextClick(goToNextPage: Function) {
          await handleNextClickLogic(goToNextPage)
        },
        async handleSaveClick() {
          await handleSaveClickLogic()
        },
      }),
      [
        selectedItem,
        schedulerState,
        serviceHasChanged,
        newOrderService,
        disposalQtyHasChanged,
      ],
    )

    useEffect(() => {
      updateSchedulerStateTires(selectedItem)
      const isFormValid = checkTireFormFields(selectedItem, setTireSizeError)
      if (isFormValid) {
        updateSchedulerStateTires(selectedItem)
      }
      setDisabled(!isFormValid)
    }, [selectedItem])

    function setSchedulerStatePsiTorque(
      frontPsi: number | null,
      rearPsi: number | null,
      torque: number | null,
    ) {
      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        tires: {
          ...prevState.tires,
          frontTire: {
            ...prevState.tires.frontTire,
            psi: frontPsi,
            torque: torque,
          },
          rearTire: {
            ...prevState.tires.rearTire,
            psi: rearPsi,
            torque: torque,
          },
        },
      }))
    }

    async function fetchAndSetPsiTorques(
      year: string,
      make: string,
      model: string,
      variant: string,
      frontTireWidth: string,
      frontTireAspectRatio: string,
      frontTireDiameter: string,
      rearTireWidth: string,
      rearTireAspectRatio: string,
      rearTireDiameter: string,
    ) {
      setAlertStatus(SUGGESTIONS_ALERT_MESSAGES.default)
      const psiTorqueValues = await fetchData<PsiTorqueSuggestions>(
        getPsiTorqueSuggestions({
          year,
          make,
          model,
          variant,
          frontTireWidth,
          frontTireAspectRatio,
          frontTireDiameter,
          rearTireWidth,
          rearTireAspectRatio,
          rearTireDiameter,
        }),
      )
      setPsiTorqueSuggestions(psiTorqueValues)

      const { frontTire, rearTire } = schedulerState.tires
      const psiAndTorqueValuesAreAlreadySet =
        rearTire.psi && rearTire.torque && frontTire.psi && frontTire.torque

      const hasOneFullSuggestion = psiTorqueValues.fullSuggestions.length === 1
      const hasMultipleFullSuggestions =
        psiTorqueValues.fullSuggestions.length > 1
      const hasOneTorqueSuggestion =
        psiTorqueValues.torqueSuggestions.length === 1
      const hasMultipleTorqueSuggestions =
        psiTorqueValues.torqueSuggestions.length > 1
      if (hasOneFullSuggestion) {
        setAlertStatus(SUGGESTIONS_ALERT_MESSAGES.uniqueMatch)
        if (!psiAndTorqueValuesAreAlreadySet) {
          const firstSuggestion = psiTorqueValues.fullSuggestions[0]
          setSchedulerStatePsiTorque(
            firstSuggestion.frontPsi,
            firstSuggestion.rearPsi,
            firstSuggestion.torque,
          )
        }
      } else if (hasMultipleFullSuggestions) {
        setAlertStatus(SUGGESTIONS_ALERT_MESSAGES.someMatches)
        setSchedulerStatePsiTorque(null, null, null)
      } else if (hasOneTorqueSuggestion) {
        setAlertStatus(SUGGESTIONS_ALERT_MESSAGES.uniqueTorque)
        setSchedulerStatePsiTorque(
          null,
          null,
          psiTorqueValues.torqueSuggestions[0],
        )
      } else if (hasMultipleTorqueSuggestions) {
        setAlertStatus(SUGGESTIONS_ALERT_MESSAGES.torqueOnly)
        setSchedulerStatePsiTorque(null, null, null)
      } else {
        setAlertStatus(SUGGESTIONS_ALERT_MESSAGES.noMatch)
        setSchedulerStatePsiTorque(null, null, null)
      }
    }
    useEffect(() => {
      const { year, make, model, variant } = schedulerState.customerInformation
      const { frontTire, rearTire } = schedulerState.tires

      if (
        year &&
        make &&
        model &&
        variant &&
        frontTire.width &&
        frontTire.aspectRatio &&
        frontTire.diameter &&
        rearTire.width &&
        rearTire.aspectRatio &&
        rearTire.diameter
      ) {
        fetchAndSetPsiTorques(
          year,
          make,
          model,
          variant,
          frontTire.width,
          frontTire.aspectRatio,
          frontTire.diameter,
          rearTire.width,
          rearTire.aspectRatio,
          rearTire.diameter,
        )
      } else {
        setAlertStatus(SUGGESTIONS_ALERT_MESSAGES.enterInformation)
        setSchedulerStatePsiTorque(null, null, null)
        setPsiTorqueSuggestions({
          fullSuggestions: [],
          torqueSuggestions: [],
        })
      }
    }, [
      schedulerState.customerInformation,
      schedulerState.tires.frontTire.width,
      schedulerState.tires.frontTire.aspectRatio,
      schedulerState.tires.frontTire.diameter,
      schedulerState.tires.rearTire.width,
      schedulerState.tires.rearTire.aspectRatio,
      schedulerState.tires.rearTire.diameter,
    ])

    const isNonCustomerMode = [
      MODES.EDIT,
      MODES.FINISH,
      MODES.SCHEDULER,
    ].includes(mode as MODES)

    return (
      <div
        className={
          mode === MODES.CUSTOMER
            ? styles.responsiveFormContainer
            : styles.formContainer
        }
      >
        {isLoading && (
          <div className={styles.overlay}>
            <div className={styles.progressContainer}>
              <CircularProgress size={80} />
            </div>
          </div>
        )}
        <PsiTorqueModal
          isOpen={isPsiTorqueModalOpen}
          psiTorqueSuggestions={psiTorqueSuggestions}
          handleCancel={() => setIsPsiTorqueModalOpen(false)}
          handleConfirm={handlePsiTorqueModalConfirm}
        />
        <SelectTireSize
          schedulerProps={props}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          mode={mode ?? ''}
        />
        <div className={styles.tireQuantityContainer}>
          <SizeQuantity {...props} />
          <div className={styles.errorContainer}>
            {tireSizeError.aspectRatio && (
              <div className={styles.errorMessage}>
                Sorry but we can only provide service on tires that have an
                aspect ratio between 7.5 and 12.5 or 30 and 95.
              </div>
            )}
            {tireSizeError.diameter && (
              <div className={styles.errorMessage}>
                Sorry but the wheel diameter of the chosen tire is too large for
                us to service.
              </div>
            )}
            {tireSizeError.width && (
              <div className={styles.errorMessage}>
                Some aftermarket wheels for tires of this size may not be able
                to be serviced. Original equipment wheels will not be a problem.
                We are not able to service tires of this size on reverse mount
                aftermarket wheels. More info on reverse mount wheels can be
                found{' '}
                <a
                  href='https://www.tirerack.com/upgrade-garage/what-are-reversemount-backmount-wheels'
                  target='_blank'
                  rel='noreferrer'
                >
                  here
                </a>
                .
              </div>
            )}
          </div>
          <TireDisposalQuantity
            setSchedulerState={setSchedulerState}
            schedulerState={schedulerState}
          />
          {isNonCustomerMode && (
            <GenericTires
              setSchedulerState={setSchedulerState}
              schedulerState={schedulerState}
            />
          )}
        </div>
        {mode !== 'customer' ? (
          <VehicleDetails
            setSchedulerState={setSchedulerState}
            schedulerState={schedulerState}
            setPsiTorqueModalOpen={setPsiTorqueModalOpen}
            alertProps={alertProps}
          />
        ) : (
          <div />
        )}
      </div>
    )
  },
)
