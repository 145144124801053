import Table from '../../../../global/components/table/Table'
import { Column } from 'react-table'
import { IOrder } from '../../../../../../app/entities/Order'
import NoFilter from '../../../../global/components/table/helpers/NoFilter'
import removeDoubleCommas from '../../../../global/utils/removeDoubleCommas'
import moment from 'moment'
import { STATUSES } from '../../../../global/constants/order-status'

interface TrOrdersTableProps {
  orderData: IOrder[]
  isLoading: boolean
}

function TrOrdersTable(props: TrOrdersTableProps) {
  const columns: Column<IOrder | any>[] = [
    {
      Header: 'Order ID',
      accessor: 'workOrderId',
      Filter: NoFilter,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: NoFilter,
    },
    {
      Header: 'Appointment',
      Cell: ({ row: { original } }) => {
        const { timeSlot, status } = original as IOrder
        const appointment =
          status === STATUSES.scheduled ? timeSlot?.fromTime : ''
        const date = appointment
          ? moment(appointment).utc().format('MM/DD/YY h:mm A')
          : ''
        return <div>{date}</div>
      },
      Filter: NoFilter,
    },
    {
      Header: 'Installation Address',
      Cell: ({ row: { original } }) => {
        const { installationPoint } = original as IOrder
        const formattedAddress = removeDoubleCommas(
          installationPoint?.fullAddress || '',
        )
        return <div>{formattedAddress}</div>
      },
      Filter: NoFilter,
    },
    {
      Header: 'Vehicle',
      Cell: ({ row: { original } }) => {
        const { orderVehicles } = original as IOrder

        const model = orderVehicles?.[0].customerVehicle?.model
        const make = orderVehicles?.[0].customerVehicle?.brand?.name
        const year = orderVehicles?.[0].customerVehicle?.year

        return <div>{`${year} ${make} ${model}`}</div>
      },
      Filter: NoFilter,
    },
    {
      Header: 'Customer Name',
      Cell: ({ row: { original } }) => {
        const { customer } = original as IOrder

        const firstName = customer?.firstName
        const lastInitial = customer?.lastName.charAt(0)

        return <div>{` ${firstName} ${lastInitial}`}</div>
      },
      Filter: NoFilter,
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        data={props.orderData ?? []}
        isLoading={props.isLoading}
        isTableTopperPresent={false}
        isDisplayMode={true}
        disableAllRowClicks
      />
    </div>
  )
}

export default TrOrdersTable
